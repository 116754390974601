@use '../../../styles/colors';
@use '../../../styles/mixin';

.BarCode {
  @extend .display-flex;
  @extend .flex-center;
  background: rgba(colors.$my-red, 0.9);
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index:colors.$zi-qrcode;
  span {
    padding: 20px;
    background: colors.$white;
    @include mixin.border-radius(2px);
  }
}