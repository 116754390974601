@use '../../../styles/colors';
@use '../../../styles/mixin';

.NotificationQueue {
  position: fixed;
  z-index: colors.$zi-notification-queue;
  top: 0;
  right: 0;
  @extend .bsbb;
  padding: 10px;
}
