@use '../../../styles/colors';
@use '../../../styles/mixin';

.UlAds {
  @extend .display-flex;
  @extend .bsbb;
  @extend .flex-wrap;
  @media screen and (min-width: 320px) {
    @include mixin.flex-row;
    border-top: 1px solid colors.$grey-300;
    padding: 20px 0 10px 0;
  }
  @media screen and (min-width: 768px) {
    @include mixin.flex-row;
    padding: 20px 0 0 0;
  }
  @media screen and (min-width: 1024px) {
    @include mixin.flex-column;
    margin-top: 20px;
    padding: 0;
    border-top: 0;
  }
  li {
    @extend .bsbb;
    @media screen and (min-width: 320px) {
      width: 50%;
      padding: 0 10px 10px 10px;
    }
    @media screen and (min-width: 768px) {
      width: 50%;
      padding: 0 20px 20px 20px;
    }
    @media screen and (min-width: 1024px) {
      width: 100%;
      padding: 0 20px 20px 20px;
    }
    img {
      width: 100%;
    }
  }
}

.Link {
  display: block;
}
