@use '../../../styles/colors';
@use '../../../styles/mixin';

.LoaderBkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(colors.$my-blue, 0.9);
  z-index: colors.$zi-page-loader;
  @extend .display-flex;
  @extend .flex-center;
}
.SkChase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.SkChaseDot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: colors.$my-yellow;
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
  }
}

.SkChaseDot:nth-child(1) {
  animation-delay: -1.1s;
}
.SkChaseDot:nth-child(2) {
  animation-delay: -1s;
}
.SkChaseDot:nth-child(3) {
  animation-delay: -0.9s;
}
.SkChaseDot:nth-child(4) {
  animation-delay: -0.8s;
}
.SkChaseDot:nth-child(5) {
  animation-delay: -0.7s;
}
.SkChaseDot:nth-child(6) {
  animation-delay: -0.6s;
}
.SkChaseDot:nth-child(1):before {
  animation-delay: -1.1s;
}
.SkChaseDot:nth-child(2):before {
  animation-delay: -1s;
}
.SkChaseDot:nth-child(3):before {
  animation-delay: -0.9s;
}
.SkChaseDot:nth-child(4):before {
  animation-delay: -0.8s;
}
.SkChaseDot:nth-child(5):before {
  animation-delay: -0.7s;
}
.SkChaseDot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
