@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.ScrollTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  @extend .display-flex;
  @extend .flex-center;
  background: colors.$my-blue;
  color: colors.$white;
  @include mixin.border-radius(50%);
  z-index: colors.$zi-scroll-top;
  cursor: pointer;
  @include mixin.box-shadow(0, 0, 5px, 0, rgba(colors.$black, 0.5));
  i {
    animation: MoveUpDown 3s linear infinite;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    margin-top: -5px;
  }
  50% {
    margin-top: 5px;
  }
}
