@use '../../../../../styles/colors';
@use '../../../../../styles/mixin';

.Mobile {
  width: 30px;
  height: 30px;
  @extend .display-flex;
  @extend .flex-center;
  @media screen and (min-width: 768px) {
    display: none;
  }
  button {
    @extend .display-flex;
    @extend .flex-center;
  }
}
