@use 'colors';
@use 'mixin';

// @font-face {
//   font-family: 'Stratum2';
//   src: url('./fonts/stratum2/Stratum2-Regular.eot');
//   src: url('./fonts/stratum2/Stratum2-Regular.eot?#iefix')
//       format('embedded-opentype'),
//     url('./fonts/stratum2/Stratum2-Regular.woff2') format('woff2'),
//     url('./fonts/stratum2/Stratum2-Regular.woff') format('woff'),
//     url('./fonts/stratum2/Stratum2-Regular.ttf') format('truetype'),
//     url('./fonts/stratum2/Stratum2-Regular.svg') format('svg');
// }

// @font-face {
//   font-family: 'Stratum2Bold';
//   src: url('./fonts/stratum2/Stratum2-Bold.eot');
//   src: url('./fonts/stratum2/Stratum2-Bold.eot?#iefix')
//       format('embedded-opentype'),
//     url('./fonts/stratum2/Stratum2-Bold.woff2') format('woff2'),
//     url('./fonts/stratum2/Stratum2-Bold.woff') format('woff'),
//     url('./fonts/stratum2/Stratum2-Bold.ttf') format('truetype'),
//     url('./fonts/stratum2/Stratum2-Bold.svg') format('svg');
// }

@font-face {
  font-family: 'Stratum2Black';
  src: url('./fonts/stratum2/Stratum2-Black.eot');
  src: url('./fonts/stratum2/Stratum2-Black.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/stratum2/Stratum2-Black.woff2') format('woff2'),
    url('./fonts/stratum2/Stratum2-Black.woff') format('woff'),
    url('./fonts/stratum2/Stratum2-Black.ttf') format('truetype'),
    url('./fonts/stratum2/Stratum2-Black.svg#Stratum2-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stratum2Bold';
  src: url('./fonts/stratum2/Stratum2-Bold.eot');
  src: url('./fonts/stratum2/Stratum2-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/stratum2/Stratum2-Bold.woff2') format('woff2'),
    url('./fonts/stratum2/Stratum2-Bold.woff') format('woff'),
    url('./fonts/stratum2/Stratum2-Bold.ttf') format('truetype'),
    url('./fonts/stratum2/Stratum2-Bold.svg#Stratum2-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stratum2Thin';
  src: url('./fonts/stratum2/Stratum2-Thin.eot');
  src: url('./fonts/stratum2/Stratum2-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/stratum2/Stratum2-Thin.woff2') format('woff2'),
    url('./fonts/stratum2/Stratum2-Thin.woff') format('woff'),
    url('./fonts/stratum2/Stratum2-Thin.ttf') format('truetype'),
    url('./fonts/stratum2/Stratum2-Thin.svg#Stratum2-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stratum2Medium';
  src: url('./fonts/stratum2/Stratum2-Medium.eot');
  src: url('./fonts/stratum2/Stratum2-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/stratum2/Stratum2-Medium.woff2') format('woff2'),
    url('./fonts/stratum2/Stratum2-Medium.woff') format('woff'),
    url('./fonts/stratum2/Stratum2-Medium.ttf') format('truetype'),
    url('./fonts/stratum2/Stratum2-Medium.svg#Stratum2-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stratum2';
  src: url('./fonts/stratum2/Stratum2-Regular.eot');
  src: url('./fonts/stratum2/Stratum2-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/stratum2/Stratum2-Regular.woff2') format('woff2'),
    url('./fonts/stratum2/Stratum2-Regular.woff') format('woff'),
    url('./fonts/stratum2/Stratum2-Regular.ttf') format('truetype'),
    url('./fonts/stratum2/Stratum2-Regular.svg#Stratum2-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stratum2Light';
  src: url('./fonts/stratum2/Stratum2-Light.eot');
  src: url('./fonts/stratum2/Stratum2-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/stratum2/Stratum2-Light.woff2') format('woff2'),
    url('./fonts/stratum2/Stratum2-Light.woff') format('woff'),
    url('./fonts/stratum2/Stratum2-Light.ttf') format('truetype'),
    url('./fonts/stratum2/Stratum2-Light.svg#Stratum2-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: colors.$white;
  color: colors.$my-blue;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 15px;
  @extend .stratum;
  vertical-align: top;
  font-weight: 400;
  color: colors.$my-dark;
  border: 0;
  outline: none;
  line-height: normal;
}
textarea {
  resize: none;
}

a,
button {
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: colors.$my-blue;
  color: colors.$white;
}

::-moz-selection {
  background-color: colors.$my-blue;
  color: colors.$white;
}

button {
  cursor: pointer;
  background: transparent;
}

h1 {
  color: colors.$my-dark;
  font-size: 25px;
  margin-bottom: 20px;
  // text-align: center;
  @extend .stratumbold;
  &.mt {
    margin-top: 50px;
  }
  text-transform: capitalize;
}

h2 {
  color: colors.$my-blue;
  font-size: 20px;
}

h3 {
  color: colors.$my-blue;
  text-transform: uppercase;
  @extend .stratumbold;
  margin-bottom: 5px;
  font-size: 15px;
  &.mt {
    margin-top: 30px;
  }
}

:disabled {
  cursor: not-allowed;
}

input,
textarea,
select {
  width: 100%;
  padding: 10px;
  @extend .bsbb;
  background: colors.$grey-100;
  @include mixin.border-radius(2px);
}

label {
  text-transform: uppercase;
  @extend .stratumbold;
  color: colors.$my-blue;
  font-size: 12px;
  display: block;
  input,
  select,
  textarea {
    margin-top: 5px;
    width: 100%;
  }
  &:not(:first-of-type) {
    margin-top: 10px;
  }
}

textarea {
  min-height: 150px;
}
