@use '../../../styles/colors';
@use '../../../styles/mixin';

.Link {
  font-size: 12px;
  margin-top: 10px;
  text-decoration: none;
  display: block;
  color: colors.$my-blue;
  &:hover {
    text-decoration: underline;
  }
}
