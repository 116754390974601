@use 'colors';
@use 'mixin';

.common-page-title {
  @extend .bsbb;
  padding-left: 50px;
  border-bottom: 1px solid colors.$grey-900;
  @extend .bsbb;
  color: colors.$grey-900;
  font-size: 30px;
  @extend .display-flex;
  @extend .flex-center-start;
  @media screen and (min-width: 320px) {
    padding: 20px 10px;
  }
  @media screen and (min-width: 768px) {
    padding: 20px;
  }
  @media screen and (min-width: 1024px) {
    padding: 50px;
  }
}

.common-page-content {
  @extend .bsbb;
  @media screen and (min-width: 320px) {
    padding: 20px 10px;
  }
  @media screen and (min-width: 768px) {
    padding: 20px;
  }
  @media screen and (min-width: 1024px) {
    padding: 50px;
  }
}

.common-admin-search {
  margin-bottom: 20px;
  @extend .display-flex;
  @extend .flex-direction-row;
  width: 100%;
}

.common-admin-add {
  background: colors.$my-blue;
  color: colors.$white;
  @extend .stratumbold;
  text-transform: uppercase;
  @extend .display-flex;
  @extend .flex-center;
  text-decoration: none;
  margin-left: 10px;
  padding: 10px;
  @extend .bsbb;
  @include mixin.border-radius(2px);
}

.common-span-spacer {
  min-height: 15px;
}

.tgl {
  display: none;
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    margin-top: 10px;
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .tgl-btn:after {
    left: 50%;
  }
}

.tgl-light {
  + .tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    &:after {
      border-radius: 50%;
      background: colors.$white;
      transition: all 0.2s ease;
    }
  }

  &:checked + .tgl-btn {
    background: colors.$my-blue;
  }
}

.common-lnk {
  text-decoration: none;
  color: colors.$my-red;
  @extend .stratumbold;
}
